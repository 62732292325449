import { Component, Inject, inject, OnInit, signal } from '@angular/core';
import { NgFor, NgIf, DatePipe, DOCUMENT, NgOptimizedImage } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { AlertService } from '../../services/alert.service';
import { BlogService } from '../services/blog.service';

import { HeaderComponent } from '../../shared/header/header.component';
import { FooterComponent } from '../../shared/footer/footer.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-post',
  standalone: true,
  imports: [RouterModule, NgFor, NgIf, DatePipe, HeaderComponent, FooterComponent, NgOptimizedImage],
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {

  listPost = signal<any>([])
  infoPostShow = signal<any>([])
  arrayShortImages = signal<any>([])
  arrayParragraphShow = signal<any>([])

  private route = inject(ActivatedRoute)
  private router = inject(Router)
  private blogService = inject(BlogService)
  private alertService = inject(AlertService)
  private titleService = inject(Title)
  private metaService = inject(Meta)

  constructor(
    @Inject(DOCUMENT) private dom: any
  ) { }

  ngOnInit(): void {
    this.getInfoAllPost()
    this.createCanonicalURL()
  }

  setSEOData(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
  }

  createCanonicalURL() {
    const baseUrl = 'https://certificadosdetradicionylibertad.com';
    const path = this.router.url.split('?')[0];
    const canonicalUrl = baseUrl + path;

    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', canonicalUrl);
  }

  getInfoAllPost() {
    this.listPost.set([])
    this.blogService.listPost$.subscribe(data => {
      if (data.length) {
        this.listPost.set(data)
        this.setInfoPost()
      } else this.blogService.getAll()
    })
  }

  setInfoPost() {
    let idPost = this.route.snapshot.params['postId']
    let findPost = this.listPost().find((item: any) => item[0] == idPost)
    if (findPost) {
      this.infoPostShow.set(findPost)
      this.setSEOData(this.infoPostShow()[1]?.title, this.infoPostShow()[1]?.introduction)
      this.transformText()
    } else {
      this.alertService.alert('error', 'Ha habido un error con la informacion del post, intenta de nuevo mas tarde.')
      this.router.navigate(['/blog'])
    }
    this.lastArrays()
  }

  transformText() {
    this.arrayParragraphShow.set([])
    this.infoPostShow()[1].paragraphs.forEach((sectionText: any) => {
      let arrayString = sectionText.content.split(' ');
      let newString = ''
      arrayString.forEach((element: string) => {
        if (element[0] === '[') {
          let aElement = element
          .replaceAll('[', '')
          .replaceAll(']', '')
          .split('||')
          newString += ` <a href="${aElement[0]}" target="_blank" rel="noopener noreferrer">${aElement[1]?.replaceAll('--', ' ')}</a> `;
        } else {
          newString += `${element} `;
        }
      })
      this.arrayParragraphShow().push(newString)
    })
    this.insertText()
  }

  insertText() {
    const boxContent = document.getElementById('paragraphs');
    if (boxContent) boxContent.innerHTML = "";
    this.arrayParragraphShow().forEach((element: any) => {
      const p = document.createElement('p')
      element.replaceAll('<h3>', '<h3 style="color: black; font-size: 20px;">')
      p.innerHTML = element;
      p.style.fontSize = '18px';
      p.style.fontWeight = '300';
      p.style.marginBottom = '20px'
      boxContent?.appendChild(p)
    })
  }

  lastArrays() {
    this.arrayShortImages.set([...this.listPost()])
    let indexElement = this.arrayShortImages().findIndex((item: any) => item[0] == this.infoPostShow()[0])
    if (indexElement > 0) {
      this.arrayShortImages().splice(indexElement, 1)
    }
    this.arrayShortImages().splice(3, this.listPost().length - 3)
  }

  onPost(titleUrl: string, id: string) {
    this.router.navigate([`/blog/post/${titleUrl}/${id}`])
    let infoNewPost = this.listPost().find((post: any) => post[0] == id)
    if (infoNewPost) {
      this.infoPostShow.set(infoNewPost)
      this.transformText()
      this.lastArrays()
      window.scrollTo(0, 0)
    }
  }

}
