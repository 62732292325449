import { Component, Inject, inject } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from '../../shared/header/header.component';

@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent],
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent {
  private titleService = inject(Title)
  private metaService = inject(Meta)

  constructor(
    @Inject(DOCUMENT) private dom: any
  ) { }

  ngOnInit(): void {
    this.setSEOData('Términos y condiciones de uso de la plataforma', 'Conoce los términos y condiciones de uso de nuestra plataforma para que puedas utilizarla de forma segura y responsable.')
    this.createCanonicalURL('https://certificadosdetradicionylibertad.com/terminos-y-condiciones')
  }

  setSEOData(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
  }

  createCanonicalURL(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href', url)
  }
}
