import { Component, inject, OnInit, signal, HostListener, Inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgClass, CurrencyPipe, DatePipe, DOCUMENT } from '@angular/common';
import { ReactiveFormsModule, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';

import { BtnNormalComponent } from '../../shared/btn-normal/btn-normal.component';
import { HeaderComponent } from '../../shared/header/header.component';
import { FooterComponent } from '../../shared/footer/footer.component';
import { InicializerService } from '../../services/inicializer.service';
import { AlertService } from '../../services/alert.service';
import { CertificatesService } from '../../services/certificates.service';
import { PinValidateService } from '../services/pin-validate.service';
import { InfoTransactionService } from '../services/info-transaction.service';

import { Office, DocumentType } from '../../models/certificados-model';
import { MustMatch } from '../../helpers/match-validators';
import { patterns } from '../../helpers/patterns';

@Component({
  selector: 'app-redeem-codes',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    HeaderComponent,
    FooterComponent,
    BtnNormalComponent,
    CurrencyPipe,
    DatePipe,
    RouterModule
  ],
  templateUrl: './redeem-codes.component.html',
  styleUrl: './redeem-codes.component.scss'
})
export class RedeemCodesComponent implements OnInit {

  form: FormGroup;
  codForm: FormGroup;
  listOffices = signal<Office[]>([])
  listOfficesToShow = signal<Office[]>([])
  typesDocs = signal<DocumentType[]>([])
  secondStep = signal<boolean>(false)
  selectedOffice = signal<any>({})
  productToPay = signal<any>({})
  valueCode = signal<number>(0)
  infoTransaction = signal<any>({})
  showOutHour = signal(false);
  showWeekend = signal(false);
  showModal = signal<boolean>(false)
  showListCities = signal<boolean>(false)

  private fb = inject(FormBuilder)
  private router = inject(Router)
  private alertService = inject(AlertService)
  private initService = inject(InicializerService)
  private certifService = inject(CertificatesService)
  private pinValidateService = inject(PinValidateService)
  private infoTransactionService = inject(InfoTransactionService)
  private titleService = inject(Title)
  private metaService = inject(Meta)

  constructor(
    @Inject(DOCUMENT) private dom: any
  ) { }

  @HostListener('click')
  hideList() {
    this.showListCities.set(false)
  }

  ngOnInit(): void {
    this.setSEOData('Redime tu código redimible', 'Si tienes un código redimible puedes crear una solicitud de certificado rellenando el formulario.')
    this.createCanonicalURL('https://certificadosdetradicionylibertad.com/redimir-codigo')
    this.setValues()
    this.setForm()
    this.setCodForm()
  }

  setSEOData(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
  }

  createCanonicalURL(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href', url)
  }

  setForm() {
    this.form = this.fb.group({
      redeemCode: new FormControl('', [Validators.required, Validators.pattern(patterns.ALL_VALID_CHARACTERES)]),
      office: new FormControl('', [Validators.required]),
      officeCode: new FormControl('000', [Validators.maxLength(3)]),
      number: new FormControl('', [Validators.required, Validators.pattern(patterns.ONLY_NUMBERS)])
    })
  }

  setCodForm() {
    this.codForm = this.fb.group({
      code: new FormControl('', [Validators.minLength(10)]),
      fullName: new FormControl('', [Validators.pattern(patterns.ONLY_LETTERS_AND_SPACE)]),
      typeDoc: new FormControl('CedulaDeCiudadania'),
      numDoc: new FormControl('', [Validators.pattern(patterns.ONLY_NUMBERS)]),
      email: new FormControl('', [Validators.required, Validators.pattern(patterns.EMAIL)]),
      confirmEmail: new FormControl('', [Validators.required, Validators.pattern(patterns.EMAIL)]),
      phone: new FormControl('', [Validators.pattern(patterns.ONLY_NUMBERS)]),
      policies: new FormControl(false, [Validators.requiredTrue])
    }, {
      validator: [
        MustMatch('email', 'confirmEmail')
      ]
    })
  }

  get ctlForm() {
    return this.form.controls
  }

  get ctlFormCode() {
    return this.codForm.controls
  }

  setValues() {
    let completeList = this.initService.inicializeOffices()
                        .map(el => {
                          el.fullName = `${el.valueOff} - ${el.nameOff}`
                          return el
                        })
    this.listOffices.set(completeList)
    this.listOfficesToShow.set(completeList)
    this.typesDocs.set(this.initService.inicializeDocumentTypes())
    this.certifService.priceCertificates$.subscribe(val => {
      if (val.length) this.productToPay.set(val[0])
    })
  }

  onInputOffice() {
    const { office } = this.form.value
    let newList = []
    if (!office) {
      this.listOfficesToShow.set(this.listOffices())
    } else {
      newList = this.listOffices()
                .filter(val => val.fullName.toLowerCase().includes(office.toLowerCase()))
      this.listOfficesToShow.set(newList)
    }
  }

  onSelectOffice(off: Office) {
    // setTimeout(() => {
    // }, 100);
    const { office, officeCode } = this.form.controls
    this.selectedOffice.set(off)
    office.setValue(`${off.valueOff} - ${off.nameOff}`)
    officeCode.setValue(off.valueOff)
    this.showListCities.set(false)
  }

  onShowListOffices() {
    setTimeout(() => this.showListCities.set(true), 10);
  }

  onValidateCode() {
    const { redeemCode } = this.form.value
    this.pinValidateService.validatePin(redeemCode)
    .subscribe({
      next: (data: any) => {
        this.valueCode.set(data.abiableMoneyRed)
      },
      error: (e) => {
        const errorMessage = e.error?.error?.message || 'Ha habido un error, intenta de nuevo mas tarde.'
        this.alertService.alert('error', errorMessage)
        this.setForm()
      }
    })
  }

  onCreateTransaction() {
    const { number } = this.form.value
    const { typeDoc, numDoc, email, phone, fullName, code } = this.codForm.value
    let objSend = {
      "request": {
        "type_product": 1,
        "type_transaction": "code",
        "certificate_type_id": 1,
        "document_type": typeDoc,
        "document_number": numDoc,
        "email": email,
        "phone": phone,
        "fullname": fullName,
        "payment_method": "code",
        "total_amount": this.productToPay().amount,
        "total_wompi": 0,
        "certificates": [{
          "id_office": this.selectedOffice().idOff,
          "office_value": this.selectedOffice().valueOff,
          "number": number
        }],
        "redeem_codes": [
          {
            "value_redeem_code": code,
            "amount_pay": this.productToPay().amount
          }
        ]
      }
    }
    this.sendObjToCreate(objSend)
  }

  sendObjToCreate(body: any) {
    this.infoTransactionService.createTransaction(body)
    .subscribe({
      next: (data: any) => {
        this.getInfoTransaction(data.transactionId)
      },
      error: () => {
        this.alertService.alert('error', 'Ha habido un error, intenta de nuevo mas tarde')
        this.router.navigate(['/'])
      }
    })
  }

  getInfoTransaction(id: string) {
    this.infoTransactionService.getInfo(id)
    .subscribe({
      next: (data: any) => {
        this.infoTransaction.set(data.request)
        this.outHour()
        this.showModal.set(true)
      },
      error: () => {
        this.alertService.alert('error', 'Ha habido un error, intenta de nuevo mas tarde')
        this.router.navigate(['/'])
      }
    })
  }

  outHour() {
    let showMessage = false;
    const actualDay = new Date(this.infoTransaction().created_at);
    actualDay.getHours() >= 19 ? showMessage = true : showMessage = false;
    actualDay.getDay() >= 1 && actualDay.getDay() <= 6
      ? this.showWeekend.set(false)
      : this.showWeekend.set(true);
    if (this.showWeekend && actualDay.getHours() >= 15) this.showWeekend.set(false);
    this.showOutHour.set(showMessage)
  }

  nextStep() {
    const { redeemCode } = this.form.value
    this.codForm.controls['code'].setValue(redeemCode)
    this.secondStep.set(!this.secondStep())
  }

  onBackStep() {
    this.setForm()
    this.setCodForm()
    this.secondStep.set(!this.secondStep())
  }

}
