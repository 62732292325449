import { Component, Inject, OnInit, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-frqu',
  templateUrl: './frqu.component.html',
  styleUrls: ['./frqu.component.scss']
})
export class FrquComponent implements OnInit {

  counterRedirect = signal(1);

  private router = inject(Router)
  private titleService = inject(Title)
  private metaService = inject(Meta)

  constructor(
    @Inject(DOCUMENT) private dom: any
  ) { }

  ngOnInit(): void {
    this.setSEOData('Preguntas Frecuentes Sobre Certificados de Traidición y Libertad', 'Resuelve aquí todas las preguntas que puedas tener sobre el Certificado de Libertad y Tradición, sus usos, su proceso de descarga y metodos de pago.')
    this.createCanonicalURL('https://certificadosdetradicionylibertad.com/preguntas-frecuentes')
  }

  setSEOData(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
  }

  createCanonicalURL(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href', url)
  }

  onRedirect() {
    if (this.counterRedirect() > 4) this.router.navigate(['/auth/login'])
    else this.counterRedirect.update(val => val += 1)
  }

}
